<template>
  <v-col cols="12" md="6" lg="6" offset-lg="3" offset-md="3">
    <v-card class="elevation-1" :loading="processing">
      <v-card-title>Attendance Computation</v-card-title>
      <v-card-text class="pb-0">
        <v-form>
          <v-text-field
            :value="activePayPeriod.end"
            outlined
            dense
            placeholder="Payroll Period"
            label="Payroll Period"
            readonly
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          :processing="processing"
          :disabled="processing"
          @click="showConfirmDialog=true">
          Process
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="processing"
          @click="openUploader=true"
          title="Upload"
        >
          <v-icon>{{icon.upload}}</v-icon> Import
        </v-btn>
      </v-card-actions>
    </v-card>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    ></snackbar>

    <confirm-password
      :show="showConfirmDialog"
      @cancel="showConfirmDialog=false"
      @execute="submit">
    </confirm-password>

    <file-uploader
      :show="openUploader"
      @closeDialog="openUploader=false"
      :accept="'.xls,.xlsx'"
      :uri="uploadUri"
    ></file-uploader>
  </v-col>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiUploadOutline } from '@mdi/js'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import FileUploader from '@/components/FileUploader.vue'
import ConfirmPassword from '@/components/ConfirmPassword.vue'

export default {
  metaInfo: {
    title: 'Attendance Computation',
  },
  components: {
    snackbar,
    ConfirmPassword,
    FileUploader,
  },
  setup() {
    const openUploader = ref(false)
    const processing = ref(false)
    const activePayPeriod = ref({})
    const formLoading = ref(false)
    const error = ref('')
    const message = ref('')
    const messageStatus = ref('')
    const showConfirmDialog = ref(false)
    const icon = {
      upload: mdiUploadOutline,
    }
    const uploadUri = `${process.env.VUE_APP_URI}/api/payroll/attendance-entries/upload`

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const submit = async () => {
      showConfirmDialog.value = false
      await store(
        `${process.env.VUE_APP_URI}/api/payroll/computation/process-attendance`,
        processing,
        { payrollPeriod: activePayPeriod.value.end },
        null,
      )
      message.value = responseMessage.value
      messageStatus.value = responseMessageStatus.value
    }

    const getPayPeriod = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/get-active`, activePayPeriod, formLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      }
    }

    getPayPeriod()

    return {
      openUploader,
      store,
      submit,
      processing,
      message,
      messageStatus,
      activePayPeriod,
      showConfirmDialog,
      icon,
      uploadUri,
    }
  },
}
</script>
